/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'xjFb8FfbKzimQQ9Ao7JayF.png': { uri: '/images/xjFb8FfbKzimQQ9Ao7JayF.png' },
'5rnx2od3HCd5TDcmQuGAjp.png': { uri: '/images/5rnx2od3HCd5TDcmQuGAjp.png' },
'7qenKLGRhRZLLaQonamhPg.png': { uri: '/images/7qenKLGRhRZLLaQonamhPg.png' },
'vnn6xevxzY35Z38S8jNHeg.png': { uri: '/images/vnn6xevxzY35Z38S8jNHeg.png' },
'df1az8W8AxBd3NfAPt53zq.png': { uri: '/images/df1az8W8AxBd3NfAPt53zq.png' },
'sfqLyqUiDX1ysSGSHFt5M7.png': { uri: '/images/sfqLyqUiDX1ysSGSHFt5M7.png' },
'iJFzD6aWwHww6RHFMRM27b.png': { uri: '/images/iJFzD6aWwHww6RHFMRM27b.png' },
'nnkUVmK7N62UuSouGEonkR.png': { uri: '/images/nnkUVmK7N62UuSouGEonkR.png' },
'o6egqVLoQtVQvsJeapjnE3.png': { uri: '/images/o6egqVLoQtVQvsJeapjnE3.png' },
'5k52DtvZ7eTxGYzypujhkT.png': { uri: '/images/5k52DtvZ7eTxGYzypujhkT.png' },
'kZbB5RsazUCoaDYkAzdHPa.png': { uri: '/images/kZbB5RsazUCoaDYkAzdHPa.png' },
'ghLYTRuDReDtXPZ1JNxrZL.png': { uri: '/images/ghLYTRuDReDtXPZ1JNxrZL.png' },
'pcqshLPb91sVFJ1Usgfdgk.png': { uri: '/images/pcqshLPb91sVFJ1Usgfdgk.png' },
'eUP5dbAeYMFJcvUN2gHXEU.png': { uri: '/images/eUP5dbAeYMFJcvUN2gHXEU.png' },
'vhQENMThVoHrKYk2zsbbCt.png': { uri: '/images/vhQENMThVoHrKYk2zsbbCt.png' },
'noXC56DYJK7NopX5kZvFro.png': { uri: '/images/noXC56DYJK7NopX5kZvFro.png' }
}

export default imageStaticSourcesByFileName
